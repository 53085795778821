.pageEvent {
    padding: 3em;
    display: flex;
    justify-content: center;
}

.pageEvent .conteneur {
    display: flex;
    flex-direction: column;
    width: 80%;
    gap: 0.5em;
}

.pageEvent .row {
    display: flex;
    gap: 0.5em;
}

.pageEvent .conteneur > .row:not(:first-child) {
    border: solid 2px var(--main);
    border-radius: 10px;
    padding: 0.5em;
}

.pageEvent .row > div {
    display: flex;
    flex-direction: column;
    max-height: 25em;
    overflow-y: auto;
    flex-grow: 1;
    width: 50%;
    padding: 0.2em;
}

.pageEvent .imageCarte {
    object-fit: contain;
    width: 15em;
    height: 15em;
}

.pageEvent .imageJeu {
    object-fit: contain;
    width: 8em;
    height: 8em;
    float: left;
}

.pageEvent .centeredCol {
    align-items: center;
    justify-content: center;
}

.pageEvent h3 {
    align-self: center;
}

.pageEvent .titre{
    flex-grow: 5 !important;
    width: 100% !important;
    align-items: center;
}

.ligneVertical {
    border-left: 2px solid grey;
    height: 15em;
}

.texteSautLigne {
    white-space: pre-line
}