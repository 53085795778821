.imageInput {
    display: flex;
    flex-direction: column;
}

.imagePreview {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1em;
    gap: 0.5em;
}

.imagePreview img {
    width: 100%;
    max-height: 20em;
    object-fit: contain;
}