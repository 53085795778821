.select {
    display: flex;
    flex-direction: column;
}

.select select {
    border-radius: 5px;
    border: solid 2px;
    height: 1.5em;
    box-sizing: content-box;
}