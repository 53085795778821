.scenario {
    border: solid 1px var(--main);
    border-radius: 10px;
    padding: 0.5em;
    display: flex;
    height: 7em;
    justify-content: space-between;
}

.logoJeu {
    height: 8em;
    width: 8em;
    object-fit: contain;
}

.gaucheScenario {
    display: flex;
    flex-direction: column;
}

.droiteScenario {
    display: flex;
    align-items: center;
}

.scenario {
    text-decoration: none;
    color: black;
    width: 80%;
}

.scenario p {
    margin: 0;
}

.texteScenario {
    display: inline-block;
    line-height: 1.5em;
    height: 4.5em;
    overflow: hidden;
    white-space: normal;
}

.titreScenario {
    margin: 0;
    display: flex;
}

.dateScenario {
    font-size: 0.9em;
    color: var(--main-lighter);
    font-style: italic;
}

.iconeCrayon {
    width: 1.5em;
    height: 1.5em;
    object-fit: contain;
    margin-left: 0.5em;
    transition: all 0.4s ease;
    cursor: pointer;
}

.iconeCrayon:hover {
    transform: rotate(365deg);
}

.lastRow {
    justify-self: end;
}