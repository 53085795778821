.listeScenarios {
    display: flex;
    justify-content: center;
    padding: 2em;
}

.scenarios {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;
    max-height: 36em;
    overflow-y: auto;
    height: 100%;
    width: 100%;
}


.headerScenario {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.modaleScenario .boutonSimple, .headerScenario .boutonSimple {
    width: 10em;
}

.modaleScenario {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 90%;
    width: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    outline: 0;
    background-color: white;
    display: flex;
    flex-direction: column;
    gap: 1em;
    padding: 1em;
    overflow-y: auto;
}

.modaleScenario .row {
    display: flex;
    justify-content: space-around;
    gap: 1em;
    width: 100%;
}

.modaleScenario .col {
    display: flex;
    flex-direction: column;
    gap: 1em;
    width: 50%;
}

.modaleScenario .vehiculeAutor {
    flex-direction: column;
    align-items: center;
}

.modaleScenario .inputDuree, .modaleScenario .inputJeu {
    flex-grow: 1;
}

.modaleScenario .inputTitre {
    flex-grow: 6;
}

.modaleScenario .sousTitre {
    margin: 0;
}

.modaleScenario .lastRow {
    margin-top: auto;
}

.modaleScenario .texteScenarioInput {
    width: 100%;
}