.event {
    border: solid 1px var(--main);
    border-radius: 10px;
    padding: 0.5em;
    display: flex;
    transition: all 0.5s ease;
    height: 7em;
    justify-content: space-between;
}

.event:hover {
    transform: scale(1.02);
}

.logoJeu {
    height: 8em;
    width: 8em;
    object-fit: contain;
}

.gaucheEvent {
    display: flex;
    flex-direction: column;
}

.droiteEvent {
    display: flex;
    align-items: center;
}

.lienEvent {
    text-decoration: none;
    color: black;
    width: 80%;
}

.event p {
    margin: 0;
}

.texteEvent {
    display: inline-block;
    line-height: 1.5em;
    height: 3em;
    overflow: hidden;
    white-space: normal;
}

.titreEvent {
    margin: 0;
    display: flex;
}

.dateEvent {
    font-size: 0.9em;
    color: var(--main-lighter);
    font-style: italic;
}

.iconeCrayon {
    width: 1.5em;
    height: 1.5em;
    object-fit: contain;
    margin-left: 0.5em;
    transition: all 0.4s ease;
    cursor: pointer;
}

.iconeCrayon:hover {
    transform: rotate(360deg);
}