.boutonSimple {
    background-color: var(--main);
    color: white;
    border: none;
    border-radius: 5px;
    height: 2em;
    font-size: 1.1em;
    width: 80%;
    cursor: pointer;
    transition: all 0.5s ease;
}

.boutonSimple:hover {
    transform: scale(1.1);
}