.accueil {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2em;
    padding: 3em 0 1em 0;
}

.grandEncar {
    border: solid 3px var(--main);
    border-radius: 10px;
    padding: 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70%;
    text-align: center;
}

.accueil .liens {
    color: black;
}

.accueil h2 {
    margin: 0;
}

.contenuEncar {
    padding: 1em;
}

.pPres {
    margin-bottom: 0;
    width: 60%;
}

.events {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1em;
    max-height: 27em;
    overflow-y: auto;
}


@media (width < 600px) {
    .pPres {
        width: 90%;
    }

    .videoYt {
        width: 100%;
        height: 150px;
    }

    .contenuEncar {
        padding: 0.1em;
    }
}