@font-face {
    font-family: Muli;
    src: url(./fonts/muli/Muli.ttf);
}

:root {
    --main: #384234;
    --main-lighter: #1a5503;
    --danger: #bc0000;
}

body {
    font-family: 'Muli', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    padding: 0;
    min-height: 100vh;
}

#root {
    width: 100%;
    min-height: inherit;
    display: flex;
    flex-direction: column;
    overflow: clip;
}

main {
    flex-grow: 1;
}


.animationLien {
    --c: #ffffff; /* the border color */
    --b: 3px;    /* the border thickness*/
    --g: 5px;     /* the gap on hover */

    border-radius: 5px;
    text-decoration: none;
    
    padding: calc(var(--g) + var(--b));
    --_g: #0000 25%,var(--c) 0;
    background: conic-gradient(from 180deg at top var(--b) right var(--b),var(--_g)) var(--_i,200%) 0 /200% var(--_i,var(--b)) no-repeat, conic-gradient(at bottom var(--b) left  var(--b),var(--_g)) 0 var(--_i,200%)/var(--_i,var(--b)) 200% no-repeat;
    transition: .3s, background-position .3s .3s;
    cursor: pointer;
}

.animationLienFonce {
    --c: var(--main); /* the border color */
    --b: 3px;    /* the border thickness*/
    --g: 5px;     /* the gap on hover */

    border-radius: 5px;
    text-decoration-style: solid;
    text-decoration-color: var(--main);
    
    padding: calc(var(--g) + var(--b));
    --_g: #0000 25%,var(--c) 0;
    background: conic-gradient(from 180deg at top var(--b) right var(--b),var(--_g)) var(--_i,200%) 0 /200% var(--_i,var(--b)) no-repeat, conic-gradient(at bottom var(--b) left  var(--b),var(--_g)) 0 var(--_i,200%)/var(--_i,var(--b)) 200% no-repeat;
    transition: .3s, background-position .3s .3s;
    cursor: pointer;
}

.animationLien:hover {
    --_i: 100%;
    transition: .3s, background-size .3s .3s;
}

.animationLienFonce:hover {
    --_i: 100%;
    transition: .3s, background-size .3s .3s;
}

/* Titres */

h1 {
    color: var(--main);
}

@media (width < 600px) {
    h1 {
        font-size: x-large;
    }
    
    h2 {
        font-size: larger;
    }

    h3 {
        font-size: large;
    }

    h4 {
        font-size: medium;
    }
}