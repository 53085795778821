.pagination {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    max-height: 36em;
    overflow-y: auto;
    align-items: center;
}

.controlesPages {
    display: flex;
    justify-content: center;
    align-items: center;
}

.boutonControle {
    border: solid white;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 7px;
    transition: transform 0.2s ease;
}

.boutonControleGauche {
    transform: rotate(135deg);
}

.boutonControleDroite {
    transform: rotate(-45deg);
}

.conteneurBouton {
    width: 3em;
    height: 3em;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.conteneurBouton:hover .boutonControleGauche {
    transform: rotate(135deg) translate(3px, 3px);
}

.conteneurBouton:hover .boutonControleDroite {
    transform: rotate(-45deg) translate(3px, 3px);
}

.contenuChildren {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5em;
    padding: 0.5em 0 0.5em 0;
}

.inputMaxParPage {
    width: 3ch;
    background-color: transparent;
    color: white;
    border: solid 1px;
    border-radius: 5px;
    outline: 0;
    font-size: 1em;
    font-family: "Muli";
}

.controlesPagesTout {
    display: flex;
    flex-direction: column;

    position: sticky;
    bottom: 0;
    background-color: var(--main);
    color: white;
    border-radius: 10px;
    width: fit-content;
    align-items: center;
}

.maxParPage {
    padding: 0 0.5em 0.5em 0.5em;
    display: flex;
    justify-content: center;
    gap: 0.5em;
}
