.inputDate {
    display: flex;
    flex-direction: column;
}

.inputDate input {
    border-radius: 5px;
    border: solid 2px;
    height: 1.5em;
    resize: vertical;
    font-family: "Muli";
}
