.inputTexte {
    display: flex;
    flex-direction: column;
}

.inputTexte input {
    border-radius: 5px;
    border: solid 2px;
    height: 1.5em;
    font-family: "Muli";
}