.genToken {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.genToken button {
    width: fit-content;
    height: fit-content;
    padding: 0.8em;
    margin: 0.5em;
}

.genToken p {
    /* text-align: center; */
    display: flex;
    flex-direction: column;
    align-items: center;
}