.footer {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 1em;
    flex-wrap: wrap;
    padding-bottom: 1em;
}

.footer > p {
    text-align: center;
}

.discord {
    height: 70px;
    width: 250px;
    transition: all 0.2s ease;
    z-index: 2;
}

.discord:hover {
    height: 500px;
    margin-top: -430px;
    align-self: flex-end;
}
