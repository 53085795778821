.listeEvents {
    display: flex;
    justify-content: center;
    padding: 2em;
}

.events {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;
    max-height: 36em;
    overflow-y: auto;
    height: 100%;
    width: 100%;
}


.headerEvent {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.modaleEvent .boutonSimple, .headerEvent .boutonSimple {
    width: 10em;
}

.modaleEvent {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 90%;
    width: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    outline: 0;
    background-color: white;
    display: flex;
    flex-direction: column;
    gap: 1em;
    padding: 1em;
    overflow-y: auto;
}

.modaleEvent .row {
    display: flex;
    justify-content: space-around;
    gap: 1em;
    width: 100%;
}

.rowHaut {
    justify-content: space-between !important;
}

.modaleEvent .inputDuree, .modaleEvent .inputJeu {
    flex-grow: 1;
}

.modaleEvent .inputTitre {
    flex-grow: 6;
}

.modaleEvent .sousTitre {
    margin: 0;
}

.modaleEvent .lastRow {
    margin-top: auto;
}

.modaleEvent .texteEventInput {
    width: 100%;
}

.selectSearchInput {
    display: flex;
    flex-direction: column;
    flex-grow: 0.8;
}

.selectSearch {
    width: 100%;
}