.inscription {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 3em 0 3em 0;
}

.inscription > div {
    border: solid 3px var(--main);
    border-radius: 10px;
    display: flex;
    width: 30%;
    flex-direction: column;
    align-items: center;
    padding: 1em;
}

.inputs {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1em;
    align-items: center;
}

.inscription h1 {
    margin: 0 0 0.5em 0;
}

.erreur {
    color: red;
}