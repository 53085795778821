.inputTexteLong {
    display: flex;
    flex-direction: column;
}

.inputTexteLong textarea {
    border-radius: 5px;
    border: solid 2px;
    height: 1.5em;
    resize: vertical;
    height: 10em;
    font-family: "Muli";
}