.header {
    width: 100%;
    height: 8em;
    position: relative;
    top: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
}

.nav-desktop {
    width: 90%;
    height: 80%;
    background-color: var(--main);
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 10px;
}

.nav-desktop .imgLogo {
    height: 8em;
    border-radius: 10px;
    border: solid white 3px;
}

.header .liens {
    color: white;
    font-size: 1.4em;
}

.nav-desktop .imgLogo {
    transition: all 0.2s ease;
}

.nav-desktop .imgLogo:hover {
    transform: scale(0.95);
}

.boutonMenuFermer, .boutonMenuOuvrir {
    display: none;
    width: 2em;
    cursor: pointer;
}

.boutonMenuOuvrir {
    position: absolute;
    right: 10%;
}

.boutonMenuFermer {
    display: none;
    position: absolute;
    align-self: self-end;
    right: 10%;
    top: 2.5em;
}

.menuMobile {
    display: none;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    padding-left: 2em;
    padding-right: 2em;
    padding-top: 50%;
    flex-direction: column;

    background-color: var(--main);
    color: white;
}

.contenuHeaderMobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2em;
}

@media (width < 600px) {
    .nav-desktop .imgLogo {
        height: 5em;
    }

    .header .liens {
        font-size: 1.2em;
    }

    .header {
        height: 5em;
        position: sticky;
        top: 1em;
    }

    .lienDesktop {
        display: none;
    }

    .menuMobile {
        display: flex;
    }

    .boutonMenuOuvrir, .boutonMenuFermer {
        display: block;
    }
}